

@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400..800;1,400..800&display=swap');

 /* @font-face {
    font-family: "Perpetua";
    src: url("./fonts/perpetua.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
    font-display: swap;
} */

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: "EB Garamond", sans-serif, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    overflow-x: hidden;
}

.MuiPaper-root {
    width: 100%;
    padding: 10px;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
