.container svg {
    transform: rotate(45deg);
  }
  
  .gradient {
    animation-iteration-count: infinite;
    animation-duration: 1s;
    fill: url("#gradient-fill");
  }
  .square {
    animation-iteration-count: infinite;
    animation-duration: 2s;
    transition-timing-function: ease-in-out;
  }
  
  .s1 {
    animation-name: slide-1;
  }
  
  .s2 {
    animation-name: slide-2;
  }
  
  .s3 {
    animation-name: slide-3;
  }
  
  .s4 {
    animation-name: slide-4;
  }
  
  .s5 {
    animation-name: slide-5;
  }
  
  .s6 {
    animation-name: slide-6;
  }
  
  .s7 {
    animation-name: slide-7;
  }
  
  @keyframes slide-1 {
    37.5% {
      transform: translateX(0px);
    }
    50% {
      transform: translateX(100px);
    }
    100% {
      transform: translateX(100px);
    }
  }
  
  @keyframes slide-2 {
    25% {
      transform: translateX(0px);
    }
    37.5% {
      transform: translateX(100px);
    }
    100% {
      transform: translateX(100px);
    }
  }
  
  @keyframes slide-3 {
    12.5% {
      transform: translateY(0px);
    }
    25% {
      transform: translateY(100px);
    }
    100% {
      transform: translateY(100px);
    }
  }
  
  @keyframes slide-4 {
    50% {
      transform: translateY(0px);
    }
    62.5% {
      transform: translateY(-100px);
    }
    100% {
      transform: translateY(-100px);
    }
  }
  
  @keyframes slide-5 {
    12.5% {
      transform: translate(-100px, 0px);
    }
    87.5% {
      transform: translate(-100px, 0px);
    }
    100% {
      transform: translate(-100px, 100px);
    }
  }
  
  @keyframes slide-6 {
    62.5% {
      transform: translateY(0px);
    }
    75% {
      transform: translateY(-100px);
    }
    100% {
      transform: translateY(-100px);
    }
  }
  
  @keyframes slide-7 {
    75% {
      transform: translateX(0px);
    }
    87.5% {
      transform: translateX(-100px);
    }
    100% {
      transform: translateX(-100px);
    }
  }
  
  /**
       * Page formatting 💅✨
       */
  
  .container {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .footer {
    margin-top: 1em;
  }
  